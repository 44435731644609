.filterDate {
  margin: 8px 10px 8px 0px;
  border: rgb(37, 161, 142, .5) 1px solid; 
  border-radius: 5px;
  padding: 15px;
  font-size: medium;
  font-family: 'Kanit';
}

.datetime-input-box-wrapper {
  color: rgb(37, 161, 142, 1) !important;
}