
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Upload';
  display: inline-block;
  background: #25A18E;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 10px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 14px;
  width: 100px;
  text-align: 'center';
}
.custom-file-input::placeholder {
  display: none;
}
.custom-file-input:hover::before {
}
.custom-file-input:active::before {
  background: #25A18E;
}

.custom-file-input:hover {
  /* background-color: #777; */
  /* border-color: #777; */
}
input[type='file'] {
  color: rgba(0, 0, 0, 0);
}


.common-buttton-primary {
  background-color: #25A18E !important;
  border-color: #25A18E !important;
}

.common-buttton-primary:hover {
  border-color: #777;
  background-color: #777;
}


.table {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  background-color: rgb(37, 161, 142, .2); 
  border: none; 
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}

.table-light {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  background-color: rgb(255, 255, 255); 
  border: none; 
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}

.table-report-light {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 1px 0px;
  color: #000000;
  background-color: rgb(255, 255, 255); 
  border: none; 
  border-radius: 1px;
  padding: 1px;
  font-size: 9.5px !important;
}

.col-report {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-direction: row;
  margin: 0px 0px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin: 10px 0px;
}

.table-transparent {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  background-color: #FFFFFF; 
  border: none; 
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}

.row-left {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
}
.col-left {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin: 10px;
}

.col-center {
  text-align: left;
  width: 180px;
  margin: 5px;
  padding: 0px 5px;
}

.option-pill {
  cursor: pointer;
}


.button-pilled-outlined {
  border: rgb(37, 161, 142) 1px solid;
  padding: 2px 5px;
  margin: 0px 5px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.mt-4 {
  margin-top: 4.5px;
}