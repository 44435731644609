@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");

:root {
  --p-theme-color-primary: #f16d22;
  --p-theme-color-secondary: #ffdec6;
  --p-theme-color-tertiary: #ff6b00;
  --p-theme-color-light: #f6f6f8;
  --p-theme-color-light-grey: #666666;
  --p-theme-color-shadow-grey: #cbcbcb;
  --p-theme-color-white: #ffffff;
  --p-theme-font-family: "Kanit", sans-serif;
  --p-theme-color-badge: #8f4196;
  --p-theme-color-card-success: #ffeee2;
  --p-theme-color-card-text-pending: #e55800;
  --p-theme-color-card-text-success: #519000;
  --p-theme-color-text-grey: #707070;
  --primary: rgb(37, 161, 142);
  --black: rgb(0, 0, 0);
  --indoor: rgb(240, 213, 144);
  --outdoor: #c8c5c1;
  --secondary: #eae6e5;
  --tertiary: #25a18e;
  --gray-200: #eaeaea;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--p-theme-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--p-theme-color-light);
}

html,
body {
  width: 100%;
  height: 100%;
}

/* @media (min-width: 600px) {
  html {
    width: 2560px;
    position: relative;
    margin: 0px auto;
    transform: translate3d(0px, 0px, 0px);
    background-color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
  }
} */

@media (min-width: 1281px) {
  .main-wrapper {
    width: 1920px !important;
  }

  .search-result-list {
    position: absolute;
    z-index: 1999;
    width: 90%;
    margin: 0px 20px 0px 10px;
    box-shadow: #eee;
  }
}

@media (min-width: 1025px) {
  .main-wrapper {
    width: 1025px !important;
  }

  .search-result-list {
    position: absolute;
    z-index: 1999;
    width: 85%;
    margin: 0px 20px 0px 10px;
    box-shadow: #eee;
  }
  /* html {
    width: 1025px;
    position: relative;
    margin: 0px auto;
    transform: translate3d(0px, 0px, 0px);
    background-color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
  } */
}

.center {
  text-align: center !important;
  vertical-align: middle !important;
}

.general-form-control {
  width: 350px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--secondary);
  border: none;
}

.bs-button-primary {
  color: var(--tertiary);
  background-color: #fff;
  border-color: #ccc;
}
.bs-button-primary:focus {
  color: var(--tertiary);
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.bs-button-primary:hover {
  color: var(--tertiary);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.bs-button-primary:active {
  color: var(--tertiary);
  background-color: #e6e6e6;
  border-color: #adadad;
}

.is-active {
  background: rgb(37, 161, 142, 0.2);
}

.themeButton {
  background-color: var(--tertiary);
}

.themeButton:hover {
  background-color: "#777";
}

.loading-indicator:before {
  content: "";
  background: rgb(229, 229, 229, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

/* .loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10001;
  color:var(--tertiary);
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
} */

.loading-indicator:after {
  content: "";
  width: 35px;
  height: 35px;
  background: url("../src/assets/images/icons/loading.gif") no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  display: inline-block;
  z-index: 10000;
  background-size: 35px 35px;
}

.input-active-terterry {
  color: var(--black);
  background-color: var(--p-theme-color-white);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
  padding: 15px 25px 5px 25px;
}

.box-wrapper {
  border: var(--primary) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: var(--p-theme-color-white);
}

.box-wrapper-white {
  border: var(--primary) 1px solid;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: var(--p-theme-color-white);
}
.box-indoor-item-wrapper {
  border: var(--primary) 1px solid;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 5px;
  background-color: var(--indoor);
}

.box-outdoor-item-wrapper {
  border: var(--primary) 1px solid;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 5px;
  background-color: var(--gray-200);
}

.custom-outlined {
  background-color: var(--p-theme-color-white) !important;
}
.dropdown-pill {
  padding: 10px 20px;
  border: var(--primary) 1px solid;
  background-color: var(--primary);
  border-radius: 10px;
  color: var(--p-theme-color-white);
  width: 200px;
  font-size: 1rem;
  cursor: pointer;
}

.title-header {
  color: var(--primary);
  font-size: 24px !important;
}

.xxxl-title-header-black {
  color: var(--black);
  font-size: 42px !important;
}

.lg-title-header {
  color: var(--primary);
  font-size: 35px !important;
}
.xl-title-header {
  color: var(--primary);
  font-size: 42px !important;
}
.title-header-black {
  color: var(--black);
  font-size: 24px !important;
}
.subtitle-header {
  color: var(--primary);
  font-size: 20px !important;
}

.text-sm-primary {
  color: var(--primary);
  font-size: 14px !important;
}

.text-sm-black {
  color: var(--black);
  font-size: 14px !important;
}

.text-lg-black {
  color: var(--black);
  font-size: 24px !important;
}

.text-xs-primary {
  color: var(--primary);
  font-size: 10px !important;
}

.text-xs-black {
  color: var(--black);
  font-size: 10px !important;
}
.text-3xs-primary {
  color: var(--primary);
  font-size: 1px !important;
}

.text-3xs-black {
  color: var(--black);
  font-size: 1px !important;
}

.box-wrapper-active-gray-200 {
  border: rgb(37, 161, 142) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: var(--gray-200);
}

.bg-gray-200 {
  background-color: var(--gray-200);
}

.box-wrapper-table {
  border: var(--primary) 1px solid;
  border-radius: 2px;
}

.subtitle-header-black {
  color: var(--black);
  font-size: 20px !important;
}
.subtitle-header-black {
  color: var(--black);
  font-size: 20px !important;
}

.large-title-header {
  color: var(--primary);
  font-size: 32px !important;
}

.large-title-header-black {
  color: var(--black);
  font-size: 32px !important;
}

.button-graph-select-active {
  color: var(--p-theme-color-white);
  background-color: rgb(37, 161, 142, 1);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
}

.button-graph-select {
  color: rgb(37, 161, 142, 1);
  border: rgb(37, 161, 142, 0.2) 1px solid;
  background-color: var(--p-theme-color-white);
  border-radius: 35px;
  box-shadow: none;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
}

.input-active {
  color: var(--p-theme-color-white);
  background-color: rgb(37, 161, 142, 1);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
  padding: 15px 25px 5px 25px;
}

.input-active-secondary {
  color: var(--p-theme-color-white);
  background-color: rgb(37, 161, 142, 0.2);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
  padding: 15px 25px 5px 25px;
}

.custom-divider {
  border: var(--primary) 1px solid;
  margin: 5px 0px;
}

.custom-divider-vertical {
  border: var(--black) 1px solid;
  margin: 5px 0px;
}

.custom-divider-vertical-primary {
  border: var(--primary) 1px solid;
  margin: 5px 0px;
}
/* .input-active-terterry {
  color: var(--black);
  background-color: var(--p-theme-color-white); 
  border: none; 
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none; 
  padding: 15px 25px 5px 25px;
} */

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: var(--black);
  background-color: rgb(37, 161, 142, 0.2);
  border: none;
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin: 10px 0px;
}

.row-ahu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
}

.col-ahu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin: 10px 0px;
}


.table-transparent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: var(--black);
  background-color: var(--p-theme-color-white);
  border: none;
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}

.table-donut-chart {
  text-align: center;
  font-size: 12px;
  color: rgb(37, 161, 142, 1);
}

.custom-donut {
  width: 250px;
}
.apexcharts-inner,
.apexcharts-graphical {
  transform: translate(0, 0) !important;
}

.col-building-type {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 300%;
  flex-direction: row;
  margin: 10px;
  padding: 15px;
  color: var(--p-theme-color-white);
  background-color: rgb(37, 161, 142, 1);
  border: none;
  border-radius: 7px;
}

.col-building-type-placeholder {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 300%;
  flex-direction: row;
  margin: 10px;
  padding: 15px;
}

.line-style-none {
  background-color: var(--p-theme-color-white);
  padding: 10px 20px;
  z-index: 1999;
  cursor: pointer;
}

.line-style-none:hover {
  z-index: 1999;
  color: var(--primary);
}

.selected-tab {
  background-color: var(--p-theme-color-white);
  color: var(--primary);
  padding: 0px 5px;
  border-radius: 5px;
}

.text-white {
  color: var(--p-theme-color-white);
}
.selected-tab-text {
  color: var(--primary);
}

#doughnut-chart-electrical-consuming-each-system-type {
  height: 150px !important;
}

.link-box {
  border-bottom: var(--primary) 2px solid;
  border-right: var(--primary) 2px solid;
  border-left: var(--primary) 2px solid;
  height: 20px;
  width: 130px;
  margin-right: 2px;
  margin-left: 2px;

  padding-left: 1px;
}

.link-box-wrap {
  width: 90px;
}

.middle-link-box {
  height: 20px;
  width: 125px;
  margin-right: 1px;
  padding-left: 1px;
}

.last-link-box {
  border-bottom: var(--primary) 2px solid;
  border-right: var(--primary) 2px solid;
  border-left: var(--primary) 2px solid;
  height: 20px;
  width: 130px;
  margin-right: 1px;
  padding-left: 1px;
}

.w-80 {
  width: 80px;
}
.w-150 {
  width: 150px;
}
.w-250 {
  width: 250px;
}
.w-350 {
  width: 350px;
}
.row-left {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
}
.col-left {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin: 10px;
}

.text-align-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.hidden {
  display: none;
}

/** BAR */
/* .tick line {
  stroke: #C0C0BB;
}
.tick text {
  fill: #635F5D;
}
.mark {
  fill: #137B80;
}
.axis-label {
  font-size: 2.5em;
  fill: #635F5D;
} */

.column-barchart {
  width: 100% !important;
}

.custom-apexcharts-yaxis-label {
  z-index: 1999 !important;
}

.ahu-table-header {
  background-color: var(--p-theme-color-light);
}

.line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
}

.text {
  font-size: 12px;
}

.title-text {
  font-size: 12px;
}

.mr-30 {
  margin-right: 120px !important;
}
