.menuItem:hover {
    background-color: '#25A18E';
}

.isActive {
    background: rgb(37, 161, 142, 0.2);
}

.changePasswordLink {
    text-decoration: none;
}