/* TODO: USE APP CSS INSTEAD */
.box-wrapper {
  border: rgb(37, 161, 142) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.box-wrapper-active {
  border: rgb(37, 161, 142) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgb(37, 161, 142, .5);
}

.box-wrapper-light {
  border: rgb(37, 161, 142) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgb(37, 161, 142, .2);
}

.text-primary {
  color: rgb(37, 161, 142);
}

.text-primary-small {
  color: rgb(37, 161, 142);
  font-size: 14px !important;
}

.text-black {
  color: rgb(0, 0, 0);
}

.text-black-lg {
  color: rgb(0, 0, 0);
  font-size: 40px;
}

.custom-outlined {
  background-color: #FFFFFF !important;
}
.dropdown-pill {
  padding: 10px 20px;
  border: rgb(37, 161, 142) 1px solid; 
  background-color: rgb(37, 161, 142); 
  border-radius: 10px;
  color: #FFFFFF;
  width: 200px;
  font-size: 1rem;
  cursor: pointer;
}


.subtitle-header {
  color: rgb(37, 161, 142); 
  font-size: 1.2rem;
}

.button-graph-select-active {
  color: #FFFFFF;
  background-color: rgb(37, 161, 142, 1); 
  border: none; 
  border-radius: 30px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
}


.button-graph-select {
  color: rgb(37, 161, 142, 1);
  border: rgb(37, 161, 142, .2) 1px solid; 
  background-color: #FFFFFF;
  border-radius: 35px;
  box-shadow: none;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
}

.input-active {
  color: #FFFFFF;
  background-color: rgb(37, 161, 142, 1); 
  border: none; 
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none;
  padding: 15px 25px 5px 25px;

}

.input-active-secondary {
  color: #FFFFFF;
  background-color: rgb(37, 161, 142, .2); 
  border: none; 
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none; 
  padding: 15px 25px 5px 25px;
}

/* .input-active-terterry {
  color: #000000;
  background-color: #FFFFFF; 
  border: none; 
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  box-shadow: none; 
  padding: 15px 25px 5px 25px;
} */


.table {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  background-color: rgb(37, 161, 142, .2); 
  border: none; 
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin: 10px 0px;
}


.table-transparent {
  display: flex;  
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  color: #000000;
  background-color: #FFFFFF; 
  border: none; 
  border-radius: 10px;
  padding: 15px 25px 5px 25px;
}

.col-building-type {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 300%;
  flex-direction: row;
  margin: 10px;
  padding: 15px;
  color: #FFFFFF;
  background-color: rgb(37, 161, 142, 1); 
  border: none; 
  border-radius: 7px;
}

.col-building-type-placeholder {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 300%;
  flex-direction: row;
  margin: 10px;
  padding: 15px;
}

.search-result-list{
  position: absolute;
  z-index: 1999;
  width: 77%;
  margin: 0px 20px 0px 10px;
  box-shadow: #EEE;
}


.line-style-none {
  background-color: #FFFFFF;
  padding: 10px 20px;
  z-index: 1999;
  cursor: pointer;
} 

.line-style-none:hover {
  z-index: 1999;
  color: rgb(37, 161, 142);
}

.pill-button-outlined-primary {
  background-color: #FFFFFF;
  border-color: rgb(37, 161, 142);
  border: 0.5px solid;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pill-button-outlined-primary-active,.pill-button-outlined-primary:hover {
  background-color: rgb(37, 161, 142);
  border-color: rgb(37, 161, 142);
  color: #FFFFFF;
  border: 0.5px solid;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
}