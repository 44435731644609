.textNoDecoration {
  text-decoration: none;
  text-decoration-line: none;
  cursor: pointer;
}

.textNoDecoration:hover {
  color: #E5E5E5;
}

